<template>
  <div id="view-welcome" class="min-h-screen pt-16 relative">
    <div
      id="banner"
      class="bg-xGray6 bg-opacity-30 myBlur shadow-lg pt-6 pb-10"
    >
      <div class="flex justify-center">
        <img
          src="@/assets/svg/ball.svg"
          alt=""
          class="w-16 h-16 transform duration-500 hover:rotate-180"
        />
      </div>
      <div class="flex flex-col mt-8 items-center">
        <p class="text-xYellow1 text-4xl font-Pacifico relative">
          TC Magdalensberg
          <span
            class="text-base uppercase text-xBlue1 absolute bottom-0 right-0 font-Quicksand font-bold transform translate-y-4 -translate-x-5"
            >Club App</span
          >
        </p>
      </div>
    </div>
    <div class="flex flex-col mt-16 items-center">
      <button
        class="myBlur border border-xWhite1 rounded-lg text-xWhite1 font-bold uppercase bg-xGray6 bg-opacity-30 shadow-lg py-2.5 px-8 w-48 mb-6 tracking-wider hover:text-xBlue1 hover:border-xBlue1"
        @click="$router.push('/login')"
      >
        Login
      </button>
      <button
        class="myBlur border border-xWhite1 rounded-lg text-xWhite1 uppercase bg-xGray6 bg-opacity-30 shadow-lg py-2.5 px-8 w-48 mb-6 tracking-wider hover:text-xBlue1 hover:border-xBlue1"
        @click="$router.push('/register')"
      >
        Registrieren
      </button>
      <a
        class="myBlur border border-xWhite1 rounded-lg text-xWhite1 uppercase bg-xGray6 bg-opacity-30 shadow-lg py-2.5 px-8 w-48 text-center tracking-wider hover:text-xBlue1 hover:border-xBlue1"
        href="https://tc-magdalensberg.at"
        >Website</a
      >
    </div>
    <div class="absolute bottom-2 right-2 text-xs text-xGray2 z-50">
      Photo by
      <a
        href="https://unsplash.com/@maur1ts?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
        >Maurits Bausenhart</a
      >
      on
      <a
        href="https://unsplash.com/s/photos/tennis?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
        >Unsplash</a
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  methods: {},
  beforeCreate() {},
};
</script>

<style scoped lang="scss">
#view-welcome {
  background-image: url("../assets/img/bg-1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
#banner {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.8);
}
</style>
