<template>
  <div id="view-verify" class="min-h-screen relative">
    <top-bar routeBack="welcome" headertext="Email verifizieren" />
    <form class="container mx-auto px-4 mt-10">
      <div
        class="bg-xGray6 bg-opacity-10 myBlur shadow-lg border border-white rounded-lg p-8 max-w-sm mx-auto"
      >
        <p class="text-xWhite1">
          Wir haben Ihnen eine Bestätigungsmail per Email zugesendet. Bitte
          klicken Sie auf den Bestätigungslink in der Email um Ihr Konto fertig
          einzurichten. Sollten Sie die Email nicht in Ihrem Posteingang finden,
          überprüfen Sie bitte auch den Spam-Ordner.
        </p>
      </div>
      <div class="flex flex-col items-center mt-10">
        <button
          class="myBlur border border-xYellow1 rounded-lg text-xYellow1 uppercase bg-xGray6 bg-opacity-30 shadow-lg py-2.5 px-8 w-48 tracking-wider hover:text-xGray6 hover:bg-xYellow1 duration-700"
          @click="push()"
        >
          Ok
        </button>
      </div>
    </form>
    <div class="absolute bottom-2 right-2 text-xs text-xGray2 z-50">
      Photo by
      <a
        href="https://unsplash.com/@maur1ts?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
        >Maurits Bausenhart</a
      >
      on
      <a
        href="https://unsplash.com/s/photos/tennis?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
        >Unsplash</a
      >
    </div>
  </div>
</template>

<script>
import TopBar from "../components/TopBar.vue";

export default {
  name: "Template",
  data() {
    return {};
  },
  methods: {
    push() {
      this.$router.push("/welcome");
    },
  },
  components: {
    TopBar,
  },
};
</script>

<style scoped lang="scss">
#view-verify {
  background-image: url("../assets/img/bg-1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
