<template>
  <div id="app" class="">
    <router-view />
    <div class="fixed bottom-4 w-full flex justify-center" v-if="updateExists">
      <div
        class="bg-xWhite1 bg-opacity-60 myBlur p-4 rounded-lg flex flex-col items-center justify-center"
      >
        <p class="text-xGray6 text-xl">Es ist ein Update der App verfügbar.</p>
        <button
          class="p-4 text-xRed font-bold border-2 border-xRed rounded-lg mt-4"
          @click="refreshApp()"
        >
          Aktualisieren
        </button>
      </div>
    </div>
    <notifications group="error" position="bottom center" />
  </div>
</template>

<script>
import appV from "../package.json";
export default {
  data() {
    return {
      registration: null,
      updateExists: false,
    };
  },
  methods: {
    updateAvailable(event) {
      console.log(event);
      this.registration = event.detail;
      this.updateExists = true;
    },
    refreshApp() {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) return;
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
    },
  },
  created() {
    console.log("v", appV.version);
    document.addEventListener("swUpdated", this.updateAvailable, {
      once: true,
    });
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing) return;
      this.refreshing = true;
      window.location.reload();
    });
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0.3;
}
</style>
