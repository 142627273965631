import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

// tailwind
import "@/css/tailwind.css";

// Moment.js & moment-range
import moment from "moment";
import "moment/locale/de";
import { extendMoment } from "moment-range";
moment.locale("de");
Vue.prototype.moment = extendMoment(moment);

// firebase
import "@/firebase/firebaseConfig";
import { firestorePlugin } from "vuefire";
Vue.use(firestorePlugin);

// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faTrash,
  faWrench,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCalendarPlus,
  faCalendarCheck,
  faSlidersH,
  faSignOutAlt,
  faPlus,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText,
} from "@fortawesome/vue-fontawesome";
library.add(
  faTrash,
  faWrench,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faCalendarPlus,
  faCalendarCheck,
  faSlidersH,
  faSignOutAlt,
  faChevronRight,
  faPlus,
  faCheck
);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("font-awesome-layers", FontAwesomeLayers);
Vue.component("font-awesome-layers-text", FontAwesomeLayersText);

// Notifications
import Notifications from "vue-notification";
Vue.use(Notifications);

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
