<template>
  <div id="component-TopBar" class="bg-xGray6 bg-opacity-30 myBlur sticky top-0 z-30">
    <div class="flex justify-between items-center max-w-7xl mx-auto px-4 py-2">
      <button @click="navBack" class=" py-1 px-2" v-if="showBack">
        <font-awesome-icon icon="chevron-left" size="lg" class="text-xYellow1"/>
      </button>
      <p class="uppercase text-xYellow1 text-sm py-2" :class="{'w-full text-center' : !showBack}">{{headertext}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    routeBack: {
      type: String,
      default: ''
    },
    headertext: {
      type: String,
      default: 'Tennis Club Magdalensberg'
    },
    showBack: {
      type: Boolean,
      default: true
    },
  },
  methods: {
    navBack () {
      if (this.routeBack == 'Website') {
        window.location = 'http://tc-magdalensberg.at';
      } else {
        this.$router.push(`/${this.routeBack}`)
      }
    }
  }
}
</script>

<style scoped>
#component-TopBar{
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.6);
}
</style>
