<template>
  <div id="view-home" class="min-h-screen relative">
    <top-bar
      routeBack="Website"
      :headertext="userWelcome"
      class="sticky top-0"
      :showBack="false"
    />

    <div class="max-w-7xl mx-auto p-4 text-xWhite1 z-20" v-if="showInfo">
      <div
        class="myBlur p-4 border border-xWhite1 rounded-lg flex flex-col items-center bg-xGray6 bg-opacity-60"
      >
        <p class="font-bold text-center text-xl">
          Willkommen in der
          <span class="text-xYellow1 font-Pacifico">TC Magdalensberg</span>
          <span class="">Club-App</span>!
        </p>
        <p class="mt-8">
          Mit dieser App können Sie die Tennisplätze des Vereins reservieren und
          Ihre bestehenden Reservierungen einsehen.
        </p>
        <p class="mt-8 text-xl font-bold">Hier die Spielregeln:</p>
        <ol class="">
          <li class="mt-2">
            1) Jedes Mitglied kann
            <span class="text-xYellow1">max. eine</span> Platzreservierungen im
            Voraus buchen.
          </li>
          <li class="mt-4">
            2) Ein Platz kann <span class="text-xYellow1">bis 16 Uhr</span> für
            <span class="text-xYellow1">mindestens 1h</span> und
            <span class="text-xYellow1">maximal 2h</span> gebucht werden.
          </li>
          <li class="mt-4">
            3) Ein Platz kann <span class="text-xYellow1">ab 16 Uhr</span> für
            <span class="text-xYellow1">maximal 1h</span> gebucht werden.
          </li>
          <li class="mt-4">
            4) Die Plätze können im Zeitraum von
            <span class="text-xYellow1">8 Uhr bis 21 Uhr</span> gebucht werden.
          </li>
          <!-- <li class="mt-4">
            4) Der Schlüssel zu den Tennisplätzen kann im
            <span class="text-xYellow1">Cafe alla Strada</span> abgeholt werden.
            Auch die Bezahlung erfolgt im Cafe alla Strada.
          </li> -->
          <li class="mt-4">
            5) Sollten Probleme mit der Reservierung oder der App auftauchen,
            melden Sie sich bitte bei
            <span class="text-xYellow1">Markus Bleiweiß</span>.
          </li>
        </ol>
        <button
          class="myBlur border border-xGreen1 rounded-lg text-xGreen1 uppercase bg-xGray6 bg-opacity-30 shadow-lg py-2.5 px-10 mt-8 tracking-wider hover:text-xWhite1 hover:bg-xGreen1 duration-700"
          @click="hideInfo()"
        >
          Verstanden
        </button>
        <div class="flex justify-center items-center px-4 mt-4">
          <input
            type="checkbox"
            name=""
            id="hideInfo"
            class="bg-green-400 w-8 h-8"
            v-model="checked"
          />
          <label for="hideInfo" class="text-sm ml-4"
            >Diese Info beim nächsten mal nicht mehr anzeigen.</label
          >
        </div>
      </div>
    </div>

    <div class="flex flex-col items-center px-4 mt-10" v-if="!showInfo">
      <button
        class="text-xs mt-6 myBlur border border-xWhite1 rounded-lg text-xWhite1 uppercase bg-xGray6 bg-opacity-30 shadow-lg py-4 px-8 w-full tracking-wider duration-700 hover:text-xBlue1 hover:border-xBlue1 font-bold sm:w-72 flex justify-center items-center"
        @click="$router.push('Reservation')"
      >
        <font-awesome-icon icon="calendar-plus" size="2x" class="" /><span
          class="ml-4"
          >Platz reservieren</span
        >
      </button>
      <button
        class="text-xs mt-6 myBlur border border-xWhite1 rounded-lg text-xWhite1 uppercase bg-xGray6 bg-opacity-30 shadow-lg py-4 px-8 w-full tracking-wider duration-700 hover:text-xGreen1 hover:border-xGreen1 font-bold sm:w-72 flex justify-center items-center"
        @click="$router.push('MyReservations')"
      >
        <font-awesome-icon icon="calendar-check" size="2x" class="" /><span
          class="ml-4"
          >Meine Reservierungen</span
        >
      </button>
      <button
        class="text-xs mt-6 myBlur border border-xWhite1 rounded-lg text-xWhite1 uppercase bg-xGray6 bg-opacity-30 shadow-lg py-4 px-8 w-full tracking-wider duration-700 hover:text-xYellow1 hover:border-xYellow1 sm:w-72 flex justify-center items-center"
        @click="$router.push('Settings')"
      >
        <font-awesome-icon icon="sliders-h" size="lg" class="" /><span
          class="ml-4"
          >Einstellungen</span
        >
      </button>
      <button
        class="text-xs mt-6 myBlur border border-xWhite1 rounded-lg text-xWhite1 uppercase bg-xGray6 bg-opacity-30 shadow-lg py-4 px-8 w-full tracking-wider duration-700 hover:text-xRed hover:border-xRed sm:w-72 flex justify-center items-center"
        @click="logout()"
      >
        <font-awesome-icon icon="sign-out-alt" size="lg" class="" /><span
          class="ml-4"
          >Logout</span
        >
      </button>
    </div>
    <div
      class="absolute bottom-2 right-2 text-xs text-xGray2 z-10"
      v-if="!showInfo"
    >
      Photo by
      <a
        href="https://unsplash.com/@ttrapani?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
        >Todd Trapani</a
      >
      on
      <a
        href="https://unsplash.com/s/photos/tennis?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
        >Unsplash</a
      >
    </div>
  </div>
</template>

<script>
import TopBar from "../components/TopBar.vue";
export default {
  data() {
    return {
      showInfo: null,
      checked: false,
    };
  },
  computed: {
    userWelcome() {
      return "Willkommen " + this.$store.state.user.firstName;
    },
  },
  components: {
    TopBar,
  },
  methods: {
    logout() {
      this.$router.push("/welcome");
      this.$store.dispatch("signOutUser");
    },
    hideInfo() {
      this.showInfo = false;

      if (this.checked) {
        this.$store.commit("SET_SHOW_INFO_SCREEN", false);
      }
    },
  },
  beforeCreate() {
    if (!this.$store.state.user.loginStatus) {
      console.log("Not logged in! Redirect...");
      this.$router.push("/welcome");
    } else {
      this.$store.dispatch("fetchReservations");
    }
  },
  beforeMount() {
    this.showInfo = this.$store.state.sessionSettings.showInfoScreen;
  },
};
</script>

<style scoped lang="scss">
#view-home {
  background-image: url("../assets/img/bg-2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
