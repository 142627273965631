import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/welcome',
    component: () => import('../views/Welcome.vue'),
    
  },
  {
    path: '/',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/register',
    component: () => import('../views/Register.vue'),
        
  },
  {
    path: '/reservation',
    component: () => import('../views/Reservation.vue'),
  },
  {
    path: '/myreservations',
    component: () => import('../views/MyReservations.vue'),
  },
  {
    path: '/verify',
    component: () => import('../views/Verify.vue'),
  },
  {
    path: '/settings',
    component: () => import('../views/Settings.vue'),
  },
  {
    path: '*',
    component: () => import('../views/Welcome.vue'),
  },
]

const router = new VueRouter({
  //mode: 'history',
  routes
})

export default router
