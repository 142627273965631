<template>
  <div id="view-settings" class="min-h-screen relative">
    <top-bar routeBack="" headertext="Einstellungen" />
    <div
      class="max-w-7xl mx-auto p-4 text-xWhite1 flex flex-col items-center justify-center py-4 px-4 myBlur bg-xGray6 bg-opacity-50"
    >
      <!-- <p class=" text-center mt-16">Hierher kommen bald ein paar App Infos, Einstellungen, Datenschutz, Kontakt Infos...</p> -->
      <div class="flex items-center justify-center mt-10">
        <input
          type="checkbox"
          name="showStartInfo"
          id="showStartInfo"
          class="w-5 h-5"
          v-model="$store.state.sessionSettings.showInfoScreen"
        />
        <label for="showStartInfo" class="ml-5"
          >Die Start-Info im Hauptmenü anzeigen.</label
        >
      </div>
      <p class="font-bold mt-10">Allgemeine Infos zur App:</p>
      <p>
        Die Testphase für diesen Prototypen der Vereinsapp läuft während der
        Saison 2021. Diese App wurde im Zuge der Bachelor-Arbeit von Thomas Otti
        und David Patscheider an der FH Kärnten erstellt. Bei technischen
        Problemen oder organisatorischen Fragen wenden Sie sich bitte in erster
        Linie an Markus Bleiweiss.
      </p>
    </div>
    <div class="absolute bottom-2 right-2 text-xs text-xGray2 z-50">
      Photo by
      <a
        href="https://unsplash.com/@ttrapani?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
        >Todd Trapani</a
      >
      on
      <a
        href="https://unsplash.com/s/photos/tennis?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
        >Unsplash</a
      >
    </div>
  </div>
</template>

<script>
import TopBar from "../components/TopBar.vue";
export default {
  data() {
    return {
      name: "",
    };
  },
  components: {
    TopBar,
  },
  computed: {
    showInfoScreen() {
      return this.$store.state.sessionSettings.showInfoScreen;
    },
  },
};
</script>

<style lang="scss">
#view-settings {
  background-image: url("../assets/img/bg-2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
