<template>
  <div id="view-reservation" class="min-h-screen relative">
    <top-bar routeBack="" headertext="Platz reservieren" />

    <div class="flex justify-center items-center mt-8">
      <button class="px-4 py-2 mr-4" @click="minus1Day()">
        <font-awesome-icon icon="chevron-left" size="2x" class="text-xWhite1" />
      </button>
      <input
        type="date"
        name="activeDate"
        id="activeDate"
        :min="moment().format('YYYY-MM-DD')"
        placeholder=""
        class="px-4 py-2 rounded-lg font-bold"
        v-model="activeDate"
      />
      <button class="px-4 py-2 ml-4" @click="plus1Day()">
        <font-awesome-icon
          icon="chevron-right"
          size="2x"
          class="text-xWhite1"
        />
      </button>
    </div>

    <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-8 max-w-7xl mx-auto">
      <div class="px-4" v-for="court in allCourts" :key="court.id">
        <div
          class="bg-xGray6 bg-opacity-75 border border-xWhite1 rounded-lg flex flex-col items-center"
        >
          <button
            class="text-xl w-full py-2 rounded-lg flex justify-between items-center px-4 outline-none focus:outline-none"
            :class="`text-${court.color}`"
            @click="showCourt(court.id)"
          >
            <span>{{ court.name }}</span>
            <div
              class="transform duration-300"
              :class="{ 'rotate-180': court.id == activeCourt }"
            >
              <font-awesome-icon
                icon="chevron-down"
                class=""
                :class="`text-${court.color}`"
              />
            </div>
          </button>
          <transition name="fade">
            <div v-if="court.id == activeCourt" class="relative w-full px-4">
              <div
                v-for="reservation in filteredReservations(
                  activeDate,
                  court.id
                )"
                :key="reservation.id"
                class="flex flex-wrap bg-xWhite1 bg-opacity-10 rounded-lg text-xWhite1 py-2 px-1 items-center m-2"
              >
                <div class="flex items-center">
                  <div class="p-2">
                    <p class="text-lg">
                      <span class="inline-block text-xs w-7">von</span>
                      {{ moment(reservation.from).format("HH:mm") }}
                    </p>
                    <p class="text-lg">
                      <span class="inline-block text-xs w-7">bis</span>
                      {{ moment(reservation.to).format("HH:mm") }}
                    </p>
                  </div>
                  <div class="ml-8">
                    {{ reservation.user.firstName }}
                    {{ reservation.user.lastName }}
                  </div>
                </div>
                <p class="px-2 flex-1 text-center" v-if="reservation.adminText">
                  {{ reservation.adminText }}
                </p>
              </div>

              <div
                v-if="showBookingDetails"
                class="bg-xWhite1 bg-opacity-20 rounded-lg text-xWhite1 mb-2 p-2 border border-xGreen2 mt-4"
              >
                <div class="flex flex-wrap items-center justify-center gap-4">
                  <div
                    class="flex-1 min-w-max flex flex-col justify-center items-center"
                  >
                    <div class="text-lg">
                      <span class="inline-block text-xs w-7">von</span>
                      <vue-timepicker
                        class="text-xGray6"
                        :minute-interval="15"
                        :hour-range="[[8, 20]]"
                        v-model="bookFrom"
                      ></vue-timepicker>
                      <!-- <input
                        type="time"
                        name="formVon"
                        id="formVon"
                        step="900"
                        min="08:00"
                        max="21:00"
                        class="bg-white bg-opacity-10 rounded"
                        v-model="bookFrom"
                      /> -->
                    </div>
                    <div class="text-lg mt-2">
                      <span class="inline-block text-xs w-7">bis</span>
                      <vue-timepicker
                        class="text-xGray6"
                        :minute-interval="15"
                        :hour-range="[[9, 21]]"
                        v-model="bookTo"
                      ></vue-timepicker>
                      <!-- <input
                        type="time"
                        name="formVon"
                        id="formTo"
                        step="900"
                        min="08:00"
                        max="21:00"
                        class="bg-white bg-opacity-10 rounded"
                        v-model="bookTo"
                      /> -->
                    </div>
                  </div>
                  <p class="flex-1 min-w-max text-center">
                    {{ activeUser.firstName }} {{ activeUser.lastName }}
                  </p>
                  <div class="flex-1 flex justify-center">
                    <button
                      class="w-12 h-12 bg-xGreen2 rounded-full flex justify-center items-center"
                      @click="submitBooking()"
                    >
                      <font-awesome-icon
                        icon="check"
                        size="2x"
                        class="text-xWhite1"
                      />
                    </button>
                  </div>
                </div>
                <div class="mt-2 w-full flex flex-wrap" v-if="isAdmin">
                  <p class="mr-2">Admin Text:</p>
                  <input
                    type="text"
                    class="text-xGray6 flex-1"
                    v-model="adminMessage"
                  />
                </div>
              </div>
              <div class="flex justify-center my-2" v-if="!showBookingDetails">
                <button
                  class="bg-xGreen2 w-10 h-10 rounded-full outline-none focus:outline-none"
                  @click="showBookingDetails = true"
                >
                  <font-awesome-icon
                    icon="plus"
                    size="lg"
                    class="text-xWhite1"
                  />
                </button>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <div class="absolute bottom-2 right-2 text-xs text-xGray2 z-10">
      Photo by
      <a
        href="https://unsplash.com/@ttrapani?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
        >Todd Trapani</a
      >
      on
      <a
        href="https://unsplash.com/s/photos/tennis?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
        >Unsplash</a
      >
    </div>
  </div>
</template>

<script>
import TopBar from "../components/TopBar.vue";
import firebase from "firebase/app";
import "firebase/firestore"; // for cloud firestore
import moment from "moment";
import bcrypt from "bcryptjs";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";

export default {
  data() {
    return {
      activeDate: "",
      activeCourt: null,
      showBookingDetails: false,
      bookFrom: "",
      bookTo: "",
      adminHashes: [
        "$2y$10$4efJLr.LE2K0eUTHpKvCIeCGC3I63QaISS0s9a0c1PcyNnDOwXN7y", // Verein (BM)
        "$2a$10$vxxufsnOQO1JY.Nomlep9.A58Y5.N4OORb3oQ8qTqCqfiwQhxSdQa", // TO
        "$2a$10$ssisO4.eM8mD7zpqIHYEZuwCTlLkCn9aw6Hb9J1d8V6eHz2.s6VYa", // PIRKER
        "$2a$10$n5KWUU03zHr/0pINZ0AY2eHZOfI250EbCHDDGzSkRoApcViz0D/Ci", // Höllhuber
        "$2a$10$d0bbXTAPk9zdBG1/H2uQuuELw.GkyuvAKmTTlH6Uo18EGp2jnRk1C", // Petzner
      ],
      isAdmin: false,
      adminMessage: "",
    };
  },
  components: {
    TopBar,
    VueTimepicker,
  },
  computed: {
    allCourts() {
      return this.$store.state.courts;
    },
    allReservations() {
      return this.$store.state.reservations;
    },
    activeUser() {
      return this.$store.state.user;
    },
  },
  watch: {
    bookFrom: function (val) {
      console.log("from: ", val);
      this.bookTo = moment(val, "HH:mm").add(1, "h").format("HH:mm");
      console.log("to: ", this.bookTo);
    },
  },
  methods: {
    plus1Day() {
      this.activeDate = moment(this.activeDate)
        .add(1, "days")
        .format("YYYY-MM-DD");
    },
    minus1Day() {
      if (moment(this.activeDate).isAfter(moment().format("YYYY-MM-DD"))) {
        this.activeDate = moment(this.activeDate)
          .subtract(1, "days")
          .format("YYYY-MM-DD");
      }
    },
    filteredReservations(date, courtID) {
      let filtered = this.allReservations.filter((element) =>
        element.court.id == courtID && element.date == date ? true : false
      );
      let sorted = filtered.sort((a, b) => {
        return moment(a.from, "HH:mm").isBefore(moment(b.from, "HH:mm"))
          ? -1
          : 1;
      });
      return sorted;
    },
    showCourt(courtID) {
      (this.showBookingDetails = false),
        this.activeCourt == courtID
          ? (this.activeCourt = null)
          : (this.activeCourt = courtID);
    },
    async submitBooking() {
      // VALIDATION - Input correct?
      let validationPassed = true;

      // Hat der User schon zwei active Buchungen?
      let currentBookingsOfUser = this.allReservations.filter((element) => {
        return (
          element.user.id == this.activeUser.id &&
          moment(moment(element.to)).isAfter(moment())
        );
      });

      if (!this.isAdmin) {
        if (currentBookingsOfUser.length >= 1) {
          validationPassed = false;
          this.$notify({
            group: "error",
            type: "error",
            duration: 3000,
            title: "Error",
            text: "Es besteht bereits eine Platzreservierung dieses Users.",
          });
          console.log(
            "Es besteht bereits eine Platzreservierung dieses Users."
          );
        }
      }

      // Zeit zwischen 08:00 und 21:00?
      let moment1 = moment(this.bookFrom, "HH:mm");
      let moment2 = moment("08:00", "HH:mm");
      let moment3 = moment(this.bookTo, "HH:mm");
      let moment4 = moment("21:00", "HH:mm");

      let isInTimeFrame =
        moment(moment1).isSameOrAfter(moment2) &&
        moment(moment3).isSameOrBefore(moment4);
      if (!isInTimeFrame) {
        validationPassed = false;
        this.$notify({
          group: "error",
          type: "error",
          duration: 3000,
          title: "Error",
          text: "Bitte eine Uhrzeit zwischen 08:00 und 21:00 eingeben.",
        });
        console.log("Bitte eine Uhrzeit zwischen 08:00 und 21:00 eingeben.");
      }

      // Uhrzeit bookFrom before bookTo
      if (moment(moment1).isAfter(moment3, "hour")) {
        validationPassed = false;
        this.$notify({
          group: "error",
          type: "error",
          duration: 3000,
          title: "Error",
          text: "Die erste Uhrzeit muss vor der zweiten liegen.",
        });
        console.log("Die erste Uhrzeit muss vor der zweiten liegen!");
      }

      // Liegt die Buchung in der Zukunft?
      if (
        moment(`${this.activeDate}T${this.bookFrom}:00`).isBefore(
          moment(),
          "hour"
        )
      ) {
        validationPassed = false;
        this.$notify({
          group: "error",
          type: "error",
          duration: 3000,
          title: "Error",
          text: "Die Startzeit liegt in der Vergangenheit.",
        });
        console.log("Die Startzeit liegt in der Vergangenheit!");
      }

      // Vor 16: 00 Dauer zwischen 1h und 2h? & nach 16: 00 Dauer genau 1h?
      if (!this.isAdmin) {
        let beforeAfterNoon = moment(moment1).isBefore(
          moment("16:00", "HH:mm")
        );
        let duration = moment.duration(moment3.diff(moment1)).as("minutes");
        if (beforeAfterNoon && (duration < 60 || duration > 120)) {
          validationPassed = false;
          this.$notify({
            group: "error",
            type: "error",
            duration: 3000,
            title: "Error",
            text: "Vor 16:00 bitte minimal 1h und maximal 2h buchen.",
          });
          console.log("Vor 16:00 bitte minimal 1h und maximal 2h buchen!");
        } else if (!beforeAfterNoon && duration != 60) {
          validationPassed = false;
          this.$notify({
            group: "error",
            type: "error",
            duration: 3000,
            title: "Error",
            text: "Nach 16:00 bitte genau 1h buchen.",
          });
          console.log("Nach 16:00 bitte genau 1h buchen!");
        }
      }

      // Überlappung mit anderen terminen
      if (validationPassed) {
        if (this.overlapping()) {
          // Überlappung mit bestehenden Reservierungen im store
          validationPassed = false;
          this.$notify({
            group: "error",
            type: "error",
            duration: 3000,
            title: "Error",
            text: "Zeitüberschneidung mit einer anderen Buchung.",
          });
          console.log("Zeitüberschneidung mit einer anderen Buchung!");
        } else {
          await this.$store.dispatch("fetchReservations");
          if (this.overlapping()) {
            // Überlappung mit Reservierungen am server
            validationPassed = false;
            this.$notify({
              group: "error",
              type: "error",
              duration: 3000,
              title: "Error",
              text: "Zeitüberschneidung mit einer anderen Buchung.",
            });
            console.log("Zeitüberschneidung mit einer anderen Buchung!");
          }
        }
      }

      // Submit
      if (validationPassed) {
        let fromDate = moment(
          `${this.activeDate}T${this.bookFrom}:00`
        ).valueOf();
        let toDate = moment(`${this.activeDate}T${this.bookTo}:00`).valueOf();

        var fromTimestamp = firebase.firestore.Timestamp.fromMillis(fromDate);
        var toTimestamp = firebase.firestore.Timestamp.fromMillis(toDate);

        let reservationObject = {
          court: {
            id: this.activeCourt,
            name: this.allCourts.find(
              (element) => element.id == this.activeCourt
            ).name,
          },
          from: fromTimestamp,
          to: toTimestamp,
          adminText: this.adminMessage,
          user: {
            id: this.activeUser.id,
            firstName: this.activeUser.firstName,
            lastName: this.activeUser.lastName[0] + ".",
          },
        };

        try {
          await this.$store.dispatch("postReservation", reservationObject);
          this.showBookingDetails = false;
          this.bookFrom = "";
          this.bookTo = "";
          this.$notify({
            group: "error",
            type: "success",
            duration: 3000,
            title: "Erfolg!",
            text: "Die Buchung konnte erfolgreich an den Server übermittelt werden.",
          });
        } catch (error) {
          console.log(error);
          this.$notify({
            group: "error",
            type: "error",
            duration: 3000,
            title: "Error",
            text: "Die Buchung konnte leider nicht erfolgreich an den Server übermittelt werden.",
          });
        }
      } else {
        console.log(
          "Die eingegebenen Daten entsprechen nicht den Rahmenbedingungen."
        );
      }
    },
    overlapping() {
      let overlapping = false;
      let range1 = moment.range([
        moment(`${this.activeDate} ${this.bookFrom}`),
        moment(`${this.activeDate} ${this.bookTo}`),
      ]);

      this.filteredReservations(this.activeDate, this.activeCourt).forEach(
        (element) => {
          let range2 = moment.range([
            moment(element.from).format("YYYY-MM-DD HH:mm"),
            moment(element.to).format("YYYY-MM-DD HH:mm"),
          ]);
          if (
            range2.contains(range1, true) ||
            range1.contains(range2, true) ||
            range1.overlaps(range2, true)
          ) {
            overlapping = true;
          }
        }
      );
      return overlapping;
    },
    async checkAdmin() {
      this.isAdmin = false;

      for (let i = 0; i < this.adminHashes.length; i++) {
        let element = this.adminHashes[i];
        let admin = await bcrypt.compare(this.$store.state.user.id, element);
        if (admin) {
          this.isAdmin = true;
        }
      }
      console.log("is admin", this.isAdmin);
    },
    setStartTime() {
      let now = moment();

      if (moment(now).minute() < 15) {
        this.bookFrom = moment().minute(15).format("HH:mm");
      } else if (moment(now).minute() < 30) {
        this.bookFrom = moment().minute(30).format("HH:mm");
      } else if (moment(now).minute() < 45) {
        this.bookFrom = moment().minute(45).format("HH:mm");
      } else {
        this.bookFrom = moment().add(1, "hours").minute(0).format("HH:mm");
      }
      this.bookTo = moment(this.bookFrom).add(1, "hours").format("HH:mm");
    },
  },
  beforeCreate() {
    if (!this.$store.state.user.loginStatus) {
      console.log("Not logged in! Redirect...");
      this.$router.push("/welcome");
    }
  },
  created() {
    this.activeDate = moment().format("YYYY-MM-DD");

    this.setStartTime();
  },

  mounted() {
    this.checkAdmin();
  },
};
</script>

<style scoped lang="scss">
#view-reservation {
  background-image: url("../assets/img/bg-2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
}
.fade-enter,
.fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
.timeTable {
  height: 520px;
}
</style>
