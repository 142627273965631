<template>
  <div id="view-register" class="min-h-screen relative">
    <top-bar routeBack="Welcome" headertext="Registrieren" />
    <form class="container mx-auto px-4 mt-10">
      <div
        class="bg-xGray6 bg-opacity-10 myBlur shadow-lg border border-white rounded-lg p-8 max-w-sm mx-auto"
      >
        <div class="flex flex-col">
          <input
            type="text"
            name="userVorname"
            id="userVorname"
            :class="{ 'placeholder-xRed border-xRed': missingFirstName }"
            placeholder="Vorname"
            class="border-b border-opacity-30 placeholder-xWhite1 placeholder-opacity-50 font-thin text-xWhite1 outline-none"
            v-model="firstName"
            @keyup.enter="register()"
          />
          <input
            type="text"
            name="userNachname"
            id="userNachname"
            :class="{ 'placeholder-xRed border-xRed': missingLastName }"
            placeholder="Nachname"
            class="mt-5 border-b border-opacity-30 placeholder-xWhite1 placeholder-opacity-50 font-thin text-xWhite1 outline-none"
            v-model="lastName"
            @keyup.enter="register()"
          />
          <input
            type="email"
            name="userEmail"
            id="userEmail"
            :class="{ 'placeholder-xRed border-xRed': missingEmail }"
            placeholder="Email"
            class="mt-5 border-b border-opacity-30 placeholder-xWhite1 placeholder-opacity-50 font-thin text-xWhite1 outline-none"
            v-model="email"
            @keyup.enter="register()"
          />
          <input
            type="password"
            name="userPassword"
            id="userPassword"
            :class="{ 'placeholder-xRed border-xRed': missingPW1 }"
            placeholder="Passwort (min. 6 Zeichen)"
            class="mt-5 border-b border-opacity-30 placeholder-xWhite1 placeholder-opacity-50 font-thin text-xWhite1 outline-none"
            v-model="password"
            @keyup.enter="register()"
          />
          <input
            type="password"
            name="userPassword2"
            id="userPassword2"
            :class="{ 'placeholder-xRed border-xRed': missingPW2 }"
            placeholder="Passwort wiederholen"
            class="mt-5 border-b border-opacity-30 placeholder-xWhite1 placeholder-opacity-50 font-thin text-xWhite1 outline-none"
            v-model="password2"
            @keyup.enter="register()"
          />
        </div>
      </div>
      <div class="flex flex-col items-center mt-10">
        <button
          class="myBlur border border-xGreen1 rounded-lg text-xGreen1 uppercase bg-xGray6 bg-opacity-30 shadow-lg py-2.5 px-8 w-48 tracking-wider hover:text-xGray6 hover:bg-xGreen1 duration-700"
          @click.prevent="register()"
        >
          Registrieren
        </button>
        <button
          class="mt-6 myBlur border border-xWhite1 rounded-lg text-xWhite1 uppercase bg-xGray6 bg-opacity-30 shadow-lg py-2.5 px-8 w-48 tracking-wider hover:bg-xRed duration-700"
          @click.prevent="reset()"
        >
          Zurücksetzen
        </button>
      </div>
    </form>
    <div class="absolute bottom-2 right-2 text-xs text-xGray2 z-50">
      Photo by
      <a
        href="https://unsplash.com/@maur1ts?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
        >Maurits Bausenhart</a
      >
      on
      <a
        href="https://unsplash.com/s/photos/tennis?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
        >Unsplash</a
      >
    </div>
  </div>
</template>

<script>
import TopBar from "../components/TopBar.vue";

export default {
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      password2: "",
      missingFirstName: null,
      missingLastName: null,
      missingEmail: null,
      missingPW1: null,
      missingPW2: null,
    };
  },
  methods: {
    async register() {
      this.missingFirstName = this.firstName != "" ? false : true;
      this.missingLastName = this.lastName != "" ? false : true;
      this.missingEmail = this.email != "" ? false : true;
      this.missingPW1 = this.password != "" ? false : true;
      this.missingPW2 = this.password2 != "" ? false : true;

      if (
        !this.missingFirstName &&
        !this.missingLastName &&
        !this.missingEmail &&
        !this.missingPW1 &&
        !this.missingPW2 &&
        this.password == this.password2
      ) {
        console.log("sending request");
        try {
          await this.$store.dispatch("registerUser", {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            password: this.password,
          });
          this.$router.push("/verify");
        } catch (error) {
          console.log(error);
          this.$notify({
            group: "error",
            type: "error",
            duration: 3000,
            title: "Error",
            text: "Bei der Registrierung ist leider ein Fehler aufgetreten!",
          });
        }
      } else {
        this.$notify({
          group: "error",
          type: "error",
          duration: 3000,
          title: "Error",
          text: "Die eingegebenen Passwörter stimmen nicht überein!",
        });
      }
    },
    reset() {
      (this.firstName = ""),
        (this.lastName = ""),
        (this.email = ""),
        (this.password = ""),
        (this.password2 = ""),
        (this.missingFirstName = null),
        (this.missingLastName = null),
        (this.missingEmail = null),
        (this.missingPW1 = null),
        (this.missingPW2 = null);
    },
  },
  components: {
    TopBar,
  },
};
</script>

<style scoped lang="scss">
input {
  background-color: transparent !important;
}
#view-register {
  background-image: url("../assets/img/bg-1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
