<template>
  <div id="view-myReservations" class="min-h-screen relative">
    <top-bar routeBack="" headertext="Meine Reservierungen" />
    <div
      class="rounded-lg flex flex-col items-center mx-auto max-w-md mt-6 pt-6 px-4"
    >
      <p class="text-xl text-xWhite1 mb-6 text-center">
        Hier findest du deine aktiven Reservierungen
      </p>
      <div
        v-for="reservation in myReservations"
        :key="reservation.id"
        class="flex justify-between items-center myBlur bg-xWhite1 bg-opacity-10 rounded-lg text-xWhite1 mb-2 myBlur w-full p-4"
      >
        <div class="flex flex-col">
          <div class="text-xl">
            {{ moment(reservation.date).format("dddd") }}
            {{ moment(reservation.date).format("D.M.YYYY") }}
          </div>
          <div class="flex">
            <p class="">
              <span class="inline-block text-xs w-7">von</span>
              {{ moment(reservation.from).format("HH:mm") }}
            </p>
            <p class="">
              <span class="inline-block text-xs w-7 ml-6">bis</span>
              {{ moment(reservation.to).format("HH:mm") }}
            </p>
          </div>
          <p class="text-xYellow1">{{ reservation.court.name }}</p>

          <p class="" v-if="reservation.adminText">
            {{ reservation.adminText }}
          </p>
        </div>
        <button
          class="rounded-full text-xWhite1 bg-red-500 w-10 h-10"
          @click="deleteBooking(reservation.id)"
        >
          <font-awesome-icon icon="trash" />
        </button>
      </div>
    </div>
    <div class="absolute bottom-2 right-2 text-xs text-xGray2 z-10">
      Photo by
      <a
        href="https://unsplash.com/@ttrapani?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
        >Todd Trapani</a
      >
      on
      <a
        href="https://unsplash.com/s/photos/tennis?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
        >Unsplash</a
      >
    </div>
  </div>
</template>

<script>
import TopBar from "../components/TopBar.vue";
import moment from "moment";

export default {
  data() {
    return {};
  },
  computed: {
    myReservations() {
      let filtered = this.$store.state.reservations.filter((element) => {
        if (element.user.id == this.$store.state.user.id) {
          if (moment(moment()).isSameOrBefore(moment(element.to))) {
            return true;
          }
        }
      });
      let sorted = filtered.sort((a, b) => {
        return moment(a.date, "YYYY-MM-DD").isBefore(
          moment(b.date, "YYYY-MM-DD")
        )
          ? -1
          : 1;
      });
      return sorted;
    },
  },
  components: {
    TopBar,
  },
  methods: {
    async deleteBooking(reservationID) {
      try {
        await this.$store.dispatch("deleteReservation", reservationID);
      } catch (error) {
        console.log(error);
      }
    },
  },
  beforeMount() {},
};
</script>

<style scoped lang="scss">
input {
  background-color: transparent !important;
}
#view-myReservations {
  background-image: url("../assets/img/bg-2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
