<template>
  <div id="view-login" class="min-h-screen relative">
    <top-bar routeBack="welcome" headertext="Login" />

    <form class="container mx-auto px-4 mt-10">
      <div
        class="bg-xGray6 bg-opacity-10 myBlur shadow-lg border border-white rounded-lg p-8 max-w-sm mx-auto"
      >
        <div class="flex flex-col">
          <input
            type="email"
            name="userEmail"
            id="userEmail"
            :class="{ 'placeholder-xRed border-xRed': missingEmail }"
            placeholder="Email"
            class="border-b border-opacity-30 placeholder-xWhite1 placeholder-opacity-50 font-thin text-xWhite1 outline-none"
            v-model="email"
            @keyup.enter="submitForm()"
          />
          <input
            type="password"
            name="userPassword"
            id="userPassword"
            :class="{ 'placeholder-xRed border-xRed': missingPW }"
            placeholder="Passwort"
            class="mt-5 border-b border-opacity-30 placeholder-xWhite1 placeholder-opacity-50 font-thin text-xWhite1 outline-none"
            v-model="password"
            @keyup.enter="submitForm()"
          />
        </div>
      </div>
      <div class="flex flex-col items-center mt-10">
        <button
          class="myBlur border border-xBlue1 rounded-lg text-xBlue1 uppercase bg-xGray6 bg-opacity-30 shadow-lg py-2.5 px-8 w-48 tracking-wider hover:text-xWhite1 hover:bg-xBlue1 duration-700"
          @click.prevent="submitForm()"
        >
          Login
        </button>
        <button
          class="text-xs mt-6 myBlur border border-xWhite1 rounded-lg text-xWhite1 uppercase bg-xGray6 bg-opacity-30 shadow-lg py-2.5 px-8 w-48 tracking-wider duration-700 hover:text-xRed"
          @click.prevent="forgotPassword()"
        >
          Passwort vergessen
        </button>
      </div>
    </form>
    <div class="absolute bottom-2 right-2 text-xs text-xGray2 z-50">
      Photo by
      <a
        href="https://unsplash.com/@maur1ts?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
        >Maurits Bausenhart</a
      >
      on
      <a
        href="https://unsplash.com/s/photos/tennis?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
        >Unsplash</a
      >
    </div>
  </div>
</template>

<script>
import TopBar from "../components/TopBar.vue";

export default {
  data() {
    return {
      email: "",
      password: "",
      missingEmail: null,
      missingPW: null,
    };
  },
  components: {
    TopBar,
  },
  methods: {
    async submitForm() {
      try {
        let verified = await this.$store.dispatch("loginUser", {
          email: this.email,
          password: this.password,
        });
        if (verified) {
          this.$router.push("/");
        } else {
          this.$notify({
            group: "error",
            type: "error",
            duration: 5000,
            title: "Error",
            text: "Anmeldung nicht erfolgreich. Bitte Username und Passwort überprüfen.",
          });
        }
      } catch (error) {
        alert(
          "Anmeldung nicht erfolgreich. Bitte Username und Passwort überprüfen!"
        );
      }
    },
    async forgotPassword() {
      if (this.email != "") {
        try {
          await this.$store.dispatch("resetPassword", this.email);
          await this.$notify({
            group: "error",
            type: "success",
            duration: 3000,
            title: "Error",
            text: "Email zum zurücksetzen des Emails wurde gesendet.",
          });
        } catch (error) {
          this.$notify({
            group: "error",
            type: "error",
            duration: 3000,
            title: "Error",
            text: "Fehler beim zurücksetzen des Passworts.",
          });
        }
      } else {
        this.$notify({
          group: "error",
          type: "error",
          duration: 3000,
          title: "Error",
          text: "Bitte Email-Adresse zum zurücksetzen eingeben.",
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
input {
  background-color: transparent !important;
}
#view-login {
  background-image: url("../assets/img/bg-1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
