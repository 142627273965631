import { auth } from "../firebase/firebaseConfig";
import { db } from "../firebase/firebaseConfig";
import moment from "moment";

export default {
  // USER
  async loginUser({ commit }, data) {
    console.log("login user...");
    try {
      let response = await auth.signInWithEmailAndPassword(
        data.email,
        data.password
      );
      if (response.user.emailVerified) {
        commit("SET_LOGIN_STATE", true);
        let result = await db
          .collection("user")
          .where("id", "==", response.user.uid)
          .get();
        result.forEach((doc) => console.log("result", doc.data()));
        result.forEach((doc) => {
          let item = doc.data();
          if (item.id == response.user.uid) {
            commit("STORE_USER", {
              firstName: item.firstName,
              lastName: item.lastName,
              id: response.user.uid,
            });
          }
        });
        return true;
      } else {
        await auth.signOut();
        commit("SET_LOGIN_STATE", false);
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  },
  async signOutUser({ commit }) {
    console.log("sign out user...");
    try {
      await auth.signOut();
      commit("SIGN_OUT_USER");
    } catch (error) {
      console.log(error);
    }
  },
  async registerUser({ commit }, data) {
    console.log("register user...");
    try {
      let response = await auth.createUserWithEmailAndPassword(
        data.email,
        data.password
      );
      commit("STORE_USER", {
        firstName: data.firstName,
        lastName: data.lastName,
        id: response.user.uid,
      });
      commit("SET_LOGIN_STATE", false);
      await db.collection("user").doc(response.user.uid).set({
        id: response.user.uid,
        firstName: data.firstName,
        lastName: data.lastName,
      });
      await response.user.sendEmailVerification();
      return true;
    } catch (error) {
      console.log(error);
      commit("SET_LOGIN_STATE", false);
    }
  },
  async resetPassword({ commit }, data) {
    console.log("reset password...", data);

    try {
      await auth.sendPasswordResetEmail(data);
      commit("SET_LOGIN_STATE", false);
      return true;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  // RESERVATIONS
  async fetchReservations({ commit }) {
    console.log("fetch reservations...");
    let reservierungen = [];
    try {
      let response = await db.collection("Reservierungen").get();
      response.forEach((doc) => {
        let item = doc.data();
        let data = {};

        data.id = doc.id;
        data.date = moment(item.from.toDate()).format("YYYY-MM-DD");
        data.from = moment(item.from.toDate()).format();
        data.to = moment(item.to.toDate()).format();
        data.adminText = item.adminText;
        data.court = item.court;
        data.user = item.user;

        reservierungen.push(data);
      });
      console.log("fetch reservations response: ", reservierungen);

      /* let sorted = reservierungen
			sorted = sorted.sort((a,b) => {
				return moment(a.from).isBefore(moment(b.from)) ? -1 : 1
			}) */

      let oldReservations = []; // Daten eine woche lang behalten
      oldReservations = reservierungen.filter((reserierung) => {
        let date1 = moment(reserierung.to).format("YYYY-MM-DD");
        let date2 = moment().subtract(7, "days").format("YYYY-MM-DD");
        if (moment(date1).isBefore(moment(date2), "day")) return true;
        else return false;
      });

      let newReservations = [];
      newReservations = reservierungen.filter((reserierung) => {
        let date1 = moment(reserierung.to).format("YYYY-MM-DD");
        let date2 = moment().format("YYYY-MM-DD");
        if (moment(date1).isSameOrAfter(moment(date2), "day")) return true;
        else return false;
      });

      console.log("reservierungen", reservierungen);
      console.log("oldReservations", oldReservations);
      console.log("newReservations", newReservations);

      if (oldReservations.length > 0) {
        for (let i = 0; i < oldReservations.length; i++) {
          const element = oldReservations[i];
          await db.collection("Reservierungen").doc(element.id).delete();
          console.log("reservation deleted: ", element.id);
        }
      }

      commit("FETCH_RESERVATIONS", newReservations);
    } catch (error) {
      console.log("fetch reservations error: ", error);
    }
  },
  async postReservation({ dispatch }, data) {
    try {
      await db.collection("Reservierungen").add(data);
      dispatch("fetchReservations");
    } catch (error) {
      console.log(error);
    }
  },
  async deleteReservation({ dispatch }, id) {
    try {
      await db.collection("Reservierungen").doc(id).delete();
      dispatch("fetchReservations");
    } catch (error) {
      console.log(error);
    }
  },
};
